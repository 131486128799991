import { navigateTo } from "gatsby-link"
import React from "react"
import Recaptcha from "react-google-recaptcha"
import "../assets/styles/index.scss"
import FileUploader from "../components/file_uploader/index"
import Footer from "../components/footer"
import Head from "../components/global/head"
import TopNavigation from "../components/global/top_navigation"
import MainLayout from "../layouts/main_layout"
import styles from "./form.module.scss"

declare const SITE_RECAPTCHA_KEY: string

interface Data {
  [key: string]: string
}

function encode(data: Data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Contact extends React.Component {
  constructor(props: {}) {
    super(props)
    this.state = {
      "enquiry-type": "Build my brand",
    }
  }

  handleChange = (e: React.SyntheticEvent) => {
    const element = e.target as HTMLInputElement
    this.setState({ [element.name as string]: element.value as string })
  }

  handleAttachment = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] })
  }

  handleRecaptcha = (value: string) => {
    this.setState({ "g-recaptcha-response": value })
  }

  handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name") as string,
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute("action") as string))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <MainLayout>
        <Head
          description="Hardhat is an independent creative agency helping brands capitalise on the why, when, where, what and how of human behaviour."
          title="Let's chat"
          url="https://hardhat.com.au/contact"
          image="https://hardhat.com.au/images/og.jpg"
        />
        <TopNavigation
          theme="dark"
          isHome={false}
          hideChat={true}
          isContactUs={true}
        />
        <div className="wrapper">
          <div className={`container ${styles.content}`}>
            <form
              className={styles.form}
              name="Enquiry"
              method="post"
              action="/thanks/"
              data-netlify="true"
              data-netlify-recaptcha="true"
              onSubmit={this.handleSubmit}
              netlify-honeypot="bot-field"
            >
              <legend className={`display1 ${styles.legend}`}>
                Let's
                <br />
                <span className="script text-yellow">chat</span>
              </legend>
              <p className={`display4 ${styles.intro}`}>
                Over a coffee, over a beer, over email, or over the phone? We’re
                good listeners and would love to hear about your
                brand&nbsp;challenges.
              </p>
              <input type="hidden" name="form-name" value="Enquiry" />
              <div className={styles.hiddenField}>
                <label>
                  Don't fill this out if you're human:{" "}
                  <input name="bot-field" />
                </label>
              </div>
              <div className={styles.field}>
                <label className={`label1 ${styles.label}`} htmlFor="name">
                  My name is
                </label>
                <input
                  className={styles.input}
                  id="name"
                  type="text"
                  name="name"
                  required={true}
                  onChange={this.handleChange}
                />
              </div>

              <div className={styles.field}>
                <label className={`label1 ${styles.label}`} htmlFor="type">
                  I'd like to
                </label>
                <div className={styles.selectWrap}>
                  <select
                    id="type"
                    name="enquiry-type"
                    required={true}
                    className={styles.select}
                    onChange={this.handleChange}
                  >
                    <option value="Build my brand">Build my brand</option>
                    <option value="Join the team">Join the team</option>
                    <option value="Discuss something else">
                      Discuss something else
                    </option>
                    <option value="Get a copy of The Why, The Book">Get a copy of 'The Why, The Book'</option>
                  </select>
                </div>
              </div>
              <div className={styles.field}>
                <label className={`label1 ${styles.label}`} htmlFor="email">
                  My email is
                </label>
                <input
                  className={styles.input}
                  id="email"
                  type="email"
                  name="email"
                  required={true}
                  onChange={this.handleChange}
                />
              </div>
              <div className={styles.field}>
                <label className={`label1 ${styles.label}`} htmlFor="phone">
                  My number is
                </label>
                <input
                  className={styles.input}
                  id="phone"
                  type="tel"
                  name="phone-number"
                  required={true}
                  onChange={this.handleChange}
                />
              </div>
              <div className={styles.field}>
                <label htmlFor="message" className={`label1 ${styles.label}`}>
                  Here's some more info
                </label>
                <textarea
                  className={[styles.input, styles.textarea].join(" ")}
                  id="message"
                  name="message"
                  onChange={this.handleChange}
                />
              </div>
              <div className={styles.field}>
                <Recaptcha
                  sitekey={SITE_RECAPTCHA_KEY}
                  onChange={this.handleRecaptcha}
                  theme="dark"
                />
              </div>
              <button
                type="submit"
                className={`${styles.button} label1 hover-target`}
                data-hover-background="rgba(253,185,19,0.4)"
              >
                Send
              </button>
            </form>
          </div>
        </div>
        <Footer />
      </MainLayout>
    )
  }
}
